#root {
  display: grid;
  grid-template-rows: 40px auto 40px;
  text-align: center;
  background-color: #282c34ed;
  background-image: url("react.svg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  height: 100vh;
  width: 100vw;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  top: 35vh;
  width: 64px;
  height: 64px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.App-logo {
  height: 30px;
}

.App-header {
  height: 40px;
  line-height: 40px;
  background-color: #282c34ed;
}

.App-link {
  color: #61dafb;
}

.App-content {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.repo {
  background-color: #282c34ed;
  margin-top: -12px;
}

.reponame {
  padding-top: 5px;
}

.repodescription {
  padding-bottom: 10px;
}

.App-footer {
  height: 40px;
  line-height: 40px;
  background-color: #282c34ed;
}
